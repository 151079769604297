import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";

import { commonService } from "../../../services";
import { useAuthState } from "../../../contexts/context";
import { Role } from "../../../helpers";
import licensedStates from "../../../constant/licensedState";

const SelectField = ({
  field_id,
  field_label,
  field_options,
  default_value,
  validation,
}) => {
  const { register, errors, control, setValue, clearErrors, getValues } =
    useFormContext();
  const [selectValue, setSelectValue] = useState(
    default_value ? JSON.parse(default_value) : null
  );
  const userDetails = useAuthState();
  // console.log("Default values >>> ", default_value);

  // load options using API call
  const loadOptions = (inputValue, callback) => {
    if (field_id == "property_state") {
      commonService.getAllStates().then((res) => {
        if (res.data.length > 0) {
          callback(res.data);
        }
      });
    }
    if (inputValue) {
      if (field_id == "zip_code_short" || field_id == "zcode_short") {
        commonService.getZipCodes(inputValue).then((res) => {
          if (res.data.length > 0) {
            callback(res.data);
          }
        });
      }
    } else callback([]);
  };

  const handleChange = async (value) => {
    // console.log(value);
    if (validationObj.source === "remote") {
      if (field_id == "zip_code_short") {
        setValue("zip_code_short", value);
        commonService.getStateByZip(value.value).then((res) => {
          if (res.data.length > 0) {
            setValue("property_state", res.data[0]);
            setSelectValue(value);
            clearErrors("zip_code_short");
            clearErrors("property_state");
          }
        });
      }
      if (field_id === "zcode_short") {
        setValue("zcode_short", value);
        commonService.getStateByZip(value.value).then((res) => {
          if (res.data.length > 0) {
            setValue("property_state", res.data[0]);
            setSelectValue(value);
            clearErrors("zcode_short");
            clearErrors("property_state");
          }
        });
      }
      if (field_id === "property_state") {
        setValue("property_state", value);
      }
    }

    if (field_id == "number_of_borrowers") {
      setValue("number_of_borrowers", value);
      if (value.value == 1) {
        setValue("co_borrower_credit_score_at_origination", null);
      }
    } else {
      setValue(field_id, value);
    }

    setSelectValue(value);
    clearErrors(field_id);
  };

  const validationObj = validation ? JSON.parse(validation) : null;
  const defaultValue = default_value ? JSON.parse(default_value) : null;
  // console.log(validationObj);

  const getSelectComponent = (field) => {
    if (validationObj.source == "remote") {
      return (
        <AsyncSelect
          cacheOptions
          {...register(field_id)}
          // defaultValue={field.defaultValue}
          isSearchable={true}
          loadOptions={loadOptions}
          onChange={handleChange}
          value={field.value}
          styles={{
            control: (provided) => {
              return { ...provided, borderRadius: "0", height: "45px" };
            },
            singleValue: (provided) => {
              return { ...provided, color: "#002db3" };
            },
          }}
        />
      );
    } else {
      return (
        <Select
          {...register(field_id)}
          options={mapFieldOptions(field_options)}
          onChange={handleChange}
          isSearchable={false}
          defaultValue={field.defaultValue}
          value={selectValue}
          styles={{
            control: (provided) => {
              return { ...provided, borderRadius: "0" };
            },
            singleValue: (provided) => {
              return {
                ...provided,
                height: "39px",
                lineHeight: "39px",
                color: "#002db3",
              };
            },
          }}
        />
      );
    }
  };

  const mapFieldOptions = (options) => {
    return options.map((option) => {
      return { label: option.option_label, value: option.option_value };
    });
  };
  const getLoanTermInfoMsg = () => {
    if (selectValue?.value === "360") {
      return "HELOC: Sum of Draw and Repay Terms; HELOAN: Final Term. Interest rates are higher on 360-month loans.";
    }
    return "HELOC: Sum of Draw and Repay Terms; HELOAN: Final Term.";
  };
  const getDrawTermInfoMsg = () => {
    if (selectValue?.value === "36") {
      return "36-month Draws have the Best Rates and Compensation Options.";
    }
    return "Rates are higher / Correspondent Compensation is lower on longer draw terms.";
  };
  const getProductRequestedMsg = () => {
    if (selectValue?.value === "Blue") {
      return "Tri-Merge Hard Pull Credit Reports required";
    }
    return "";
  };
  return (
    <div className="mb-3">
      <label className="form-label">{field_label}</label>
      <Controller
        key={field_id}
        control={control}
        name={field_id}
        onChange={handleChange}
        defaultValue={defaultValue}
        // value={selectValue}
        render={({ field }) => getSelectComponent(field)}
        rules={{ required: "This field is required" }}
      />
      <small
        hidden={field_id != "2ndmortgage_loan_term"}
        className="form-text text-black"
      >
        {getLoanTermInfoMsg()}
      </small>
      <small
        hidden={field_id != "2ndmortgage_draw_term"}
        className="form-text text-black"
      >
        {getDrawTermInfoMsg()}
      </small>
      <small
        hidden={field_id != "product_requested"}
        className="form-text text-black"
      >
        {getProductRequestedMsg()}
      </small>
      {field_id === "property_state" && getValues("property_state") ? (
        <>
          {getValues("property_state")?.value === "TX" && (
            <small className="form-text text-black">
              Only HELOANs are available in TX. HELOCs are not available.
            </small>
          )}
          {getValues("property_state")?.value === "TN" && (
            <small className="form-text text-black">
              Maximum loan term in TN is 180 months.
            </small>
          )}
          {!licensedStates.includes(getValues("property_state")?.value) && (
            <small className="form-text text-black">
              Not Licensed in {getValues("property_state")?.value}. Coming Soon!
            </small>
          )}
        </>
      ) : (
        ""
      )}
      <ErrorMessage
        errors={errors}
        name={field_id}
        render={({ message }) => <p className="fb-error-message">{message}</p>}
      />
    </div>
  );
};

export default SelectField;
